:root {
    --heading-font-family: "Soho Gothic", sans-serif;
    --text-font-family: "Soho Gothic", sans-serif;


}

.fullscreen-video-wrap video {
    width: 100%;
    justify-content: center;
    height: 450px;
    display: flex;
    scale: 700%;
    top: 650px;
    position: relative;

}
.videoBoxInfo {
    top: 50%;
    left: 20%;
    text-align: center;
    color: #fff;
  }

* {
    font-family: var(--text-font-family);
    font-weight: var(--text-font-weight);
    font-style: var(--text-font-style);
    color: var(--text-color);
    background: var(--background);
    line-height: 1.65;
}

.new {
    width: 375.25px;
    height: 400.8px;
    padding: 0 30px;
}

.products {
    padding: 80px 0px 80px 0px;
}

.headtext {
    font-size: 30pt;
    text-align: center;
    font-weight: 300;
    font-style: normal;
}

/* General Flickity buttons */
.flickity-prev-next-button {
    padding: 0%;
    position: absolute;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 50%;
    background: var(--button-text-color);
    color: var(--button-background);
    cursor: pointer;
    text-align: center;
    z-index: 1;
    box-shadow: 0 2px 10px rgba(54, 54, 54, 0.15);
    vertical-align: middle;
    line-height: 0;
    transform: scale(1.001);
    transition: background 0.15s ease-in-out, opacity 0.15s ease-in-out, transform 0.2s ease-in-out, color 0.2s ease-in-out;
    overflow: hidden;
}

.mainImage img {
    width: 315px;
    vertical-align: bottom;
    margin-top: 70px;
}

.mainImage {
    height: 252.25px;
}

.newcard {
    width: 315px;
}

/* Main product Flickity buttons */
.mainproducts {
    margin: 0 80px;
    height: 393.77px;

}

.mainproducts .flickity-prev-next-button.next {
    right: -50px;
}

.mainproducts .flickity-prev-next-button.previous {
    left: -50px;
}

/* Swatch navigation Flickity buttons */
.swatchnav {
    width: 215px;
    height: 62.5px;
    margin: auto;
}

.swatchnav .flickity-prev-next-button {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 12%;
}

.swatchnav .flickity-prev-next-button.next {
    right: -20px;
}

.swatchnav .flickity-prev-next-button.previous {
    left: -20px;
}

.swatchnav img {
    width: 75px;
}

.headline {
    margin-bottom: 70px;
}

.swatchcell {
    height: 40px;
    width: 90px;
    margin-left: 10px;
    padding: 0.25rem 1.5rem 0.25rem 0;
    border-radius: 10px;
    overflow: hidden;
    /* align-items: center; */
}
.swatchcell:hover {
    border: 1px solid #000;
}

.swatchcell img {
    transition: transform 0.3s ease-in-out; /* Smooth zoom effect */
}

.swatchcell:hover img {
    transform: scale(1.5); /* Zoom effect on hover, 1.2 means 20% bigger */
    padding: 0 0 0 10px;
}


.mdtt {
    padding: 30px 0 0 0;
    text-align: center;
    font-weight: lighter;
    margin: 0 45% 0 45%;

}

.products {
    position: relative;
}

.banner-img {
    width: 450px;
}

.banner-btn {
    display: none;
}

.banner-tt {
    display: none;
}

.small-banner:hover .banner-img {
    opacity: 0.5;
    /* Reduces the opacity to 50% on hover */
}

.small-banner:hover .banner-tt,
.small-banner:hover .banner-btn {
    display: block;
    /* Displays the text and button on hover */
}
.btnn{
    text-align: center;
}
.icon{
    left: 200px;
}

@media (max-width: 440px) {
    .fullscreen-video-wrap video {
        width: 100%;
        justify-content: center;
        height: 300px;
        display: flex;
        scale: 300%;
        top: 350px;
        position: relative;
    
    }
    .videoBoxInfo {
        top: 50%;
        left: 20%;
        text-align: center;
        color: #fff;
      }
    .products {
        padding: 50px 0px 50px 0px;
    }

    .mainproducts {
        margin: 0 0 0 0;
        height: 393.77px;
        width: 100%;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 30px;
        margin-bottom: -30px;
    }

    .mainproducts .flickity-prev-next-button {
        display: none;
    }

    .mainImage img {
        width: 242.59px;
        vertical-align: bottom;
        margin-top: 70px;
    }

    .mainImage {
        width: 242.59px;
    }

    .new {
        width: 320px;
        height: 400.8px;
        padding: 0 30px;
        margin: 0 0 0 0;
    }

    .newcard {
        width: 269px;
    }

    .swatchnav .flickity-prev-next-button {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 15%;
        display: inline;
    }

    .mdtt {
        padding: 0 0 0 0;
        text-align: center;
        font-weight: lighter;
        margin: 0 37% 0 35%;


    }

    .small-banner {
        width: 599.99px;
    }

    .banner-btn {
        display: block;
    }

    .banner-tt {
        display: block;
    }

    .banner-img {
        opacity: 70%;
    }

    .buttongrp {
        position: relative;
        bottom: 280px;
        left: 100px;
        background-color: antiquewhite;
        width: 150px;
        text-align: center;
        display: none;
    }
    .headtext{
        font-size: 15pt;
    }
}

@media (min-width:769px)and(max-width: 1024px) {
    .products {
        padding: 220px 0px 80px 0px;
        height: 100%;
    }

    .small-banner {
        width: 300px;
    }
}

@media (min-width:450px) and (max-width:768px) {
    .small-banner {
        width: 48%;
    }

    .banner-btn {
        display: block;
    }

    .banner-tt {
        display: block;
    }

    .banner-img {
        opacity: 70%;
    }
}