.mens{
    height: 494px;
    display: flex;
    justify-content: center;  /* Horizontally centers the content */
    align-items: center;      /* Vertically centers the content */           /* Takes the full viewport height */
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.title{
    font-size: 70pt;
    padding-top: 40%;
    font-style: normal;
    color: #fdfdfd;
    font-weight: 100;
    font-family: "Soho Gothic" sans-serif;
    text-shadow: 0 2px 10px rgb(15, 15, 15);
}
.tpimg img{
    width: 760px;
    padding-top: 157.81px;
    margin: 0px 0px 0px 0px ;
}
.card{
    border-radius: 0%;
    border: 0%;
    border-color: rgb(255, 255, 255);
    width: 760px;
}
.color-nav{
    width: 90%;
    padding: 0 0 0 0;

}
.color-img{
    width: 140px;
}
.tpd{
    width: 760px;
    height: 560px;
}
.card-body{
    padding: 0 0 0 0;
}
.color-nav .flickity-prev-next-button{
    display: none;
}
.productlist{
    padding: 0 20px 0 20px;
    justify-content: center;
    border-radius: 10px;
    overflow: hidden;
    height: 80px;
    width: 185px;
    justify-content: center;
}
.color-nav .flickity-viewport{
    height: 50px;
}
#pdc{
    width: 760px;
    padding: 0 0 0 30px; 
}
.collection-ro{
    gap: 100px;
    justify-content: none;
}
.card-title{
    font-style: normal;
    font-weight: bold;
    font-family: "Soho Gothic" sans-serif;
    padding-bottom: 35px;
    font-size: 21px;
}
.productlist:hover {
    border: 1px solid #000;
}

.productlist img {
    transition: transform 0.3s ease-in-out; /* Smooth zoom effect */
}

.productlist:hover img {
    transform: scale(1.5); /* Zoom effect on hover, 1.2 means 20% bigger */
    padding: 15px 0 0 5px;
}
@media (min-width:360px)and (max-width:380px){
    .mens{
        height: 494px;
        display: flex;
        justify-content: center;  /* Horizontally centers the content */
        align-items: center;      /* Vertically centers the content */           /* Takes the full viewport height */
        text-align: center;
    }
    .card{
        border-radius: 0%;
        border: 0%;
        border-color: rgb(255, 255, 255);
        width: 170px;
        height: 319.5px;
    }
    #pdc{
        width: 170px;
        padding: auto; 
    }
    .tpimg img{
        width: 160px;
        padding-top: 62.5px;
        margin: 0px 0px 0px 0px ;
    }
    .color-img{
        width: 75px;
    }
    .productlist{
        padding: 0 5px 0 5px;
        justify-content: center;
        width: 100px;
        height: 50px;
    }
    .collection-ro{
        gap: 2px;
    }
    .tpd{
        width: 160px;
        height: 160px;
    }
    .productlist{
        padding: 0 20px 0 20px;
        justify-content: center;
    }
    .card-body{
        padding: 0 0 0 0;
        align-content: center;
    }
    .card-title{
        font-style: normal;
        font-weight: lighter;
        font-family: "Soho Gothic" sans-serif;
        padding-top: 15px;
        font-size: 15pt;
        color: rgba(0, 0, 0, 0.491);
    }
    .title{
        font-size: 25pt;
        padding-top: 80%;
        font-style: normal;
        font-weight: 100;
        font-family: "Soho Gothic" sans-serif;
    }
}
@media (min-width:381px) and (max-width:460px){
    .mens{
        height: 494px;
        display: flex;
        justify-content: center;  /* Horizontally centers the content */
        align-items: center;      /* Vertically centers the content */           /* Takes the full viewport height */
        text-align: center;
    }
    .card{
        border-radius: 0%;
        border: 0%;
        border-color: rgb(255, 255, 255);
        width: 200px;
        height: 319.5px;
    }
    #pdc{
        width: 200px;
        padding: auto; 
    }
    
    .tpimg img { 
        width: 180px;
        padding-top: 62.5px;
        margin: 0px 0px 0px 0px ;
    }
    .color-img{
        width: 75px;
    }
    .productlist{
        padding: 0 5px 0 5px;
        justify-content: center;
        width: 100px;
        height: 50px;
    }
    .collection-ro{
        gap: 5px;
    }
    .tpd{
        width: 190px;
        height: 190px;
    }
    .productlist{
        padding: 0 20px 0 20px;
        justify-content: center;
    }
    .card-body{
        padding: 0 0 0 0;
        align-content: center;
    }
    .card-title{
        font-style: normal;
        font-weight: lighter;
        font-family: "Soho Gothic" sans-serif;
        padding-top: 15px;
        font-size: 10pt;

        color: rgba(0, 0, 0, 0.491);
    }
    .title{
        font-size: 20pt;
        padding-top: 80%;
        font-style: normal;
        font-weight: 100;
        font-family: "Soho Gothic" sans-serif;
    }

}
@media (min-width: 461px) and (max-width: 768px) {
    .mens{
        height: 494px;
        display: flex;
        justify-content: center;  /* Horizontally centers the content */
        align-items: center;      /* Vertically centers the content */           /* Takes the full viewport height */
        text-align: center;
    }
    .card{
        border-radius: 0%;
        border: 0%;
        border-color: rgb(255, 255, 255);
        width: 372px;
        height: 480.8px;
    }
    #pdc{
        width: 348px;
        padding: 0 0 0 25px; 
    }
    .tpimg img{
        width: 348px;
        padding-top: 122.5px;
        margin: 0px 0px 0px 0px ;
    }
    .color-img{
        width: 95px;
    }
    .productlist{
        padding: 0 5px 0 5px;
        justify-content: center;
    }
    .collection-ro{
        gap: 25px;
    }
    .tpd{
        width: 348px;
        height: 348px;
    }
    .productlist{
        padding: 0 20px 0 20px;
        justify-content: center;
    }
    .card-body{
        padding: 0 0 0 0;
        align-content: center;
    }
    .card-title{
        font-style: normal;
        font-weight: lighter;
        font-family: "Soho Gothic" sans-serif;
        padding-top: 15px;
        font-size: 15pt;
        color: rgba(0, 0, 0, 0.491);
    }
    .title{
        font-size: 50pt;
        padding-top: 40%;
        font-style: normal;
        font-weight: 100;
        font-family: "Soho Gothic" sans-serif;
    }
}
@media (min-width:770px) and (max-width:2400px){
    .mens{
        height: 494px;
        display: flex;
        justify-content: center;  /* Horizontally centers the content */
        align-items: center;      /* Vertically centers the content */           /* Takes the full viewport height */
        text-align: center;
    }
    .card{
        border-radius: 0%;
        border: 0%;
        border-color: rgb(255, 255, 255);
        width: 100%;
        height: 608.8px;
    }
    #pdc{
        width: calc(100% - 68.5%);
        padding: 0 0 0 25px; 
    }
    .tpimg img{
        width: 100%;
        padding-top: 172.5px;
        margin: 0px 0px 0px 0px ;
    }
    .color-img{
        width: 135px;
    }
    .productlist{
        padding: 0 5px 0 5px;
        justify-content: center;
    }
    .collection-ro{
        gap: 25px;
        padding-left: 50px;
    }
    .tpd{
        width: 476px;
        height: 476px;
    }
    .productlist{
        padding: 0 20px 0 20px;
        justify-content: center;
    }
    .card-body{
        padding: 0 0 0 0;
        align-content: center;
    }
    .card-title{
        font-style: normal;
        font-weight: lighter;
        font-family: "Soho Gothic" sans-serif;
        padding-top: 15px;
        font-size: 15pt;
        color: rgba(0, 0, 0, 0.491);
    }
    .title{
        font-size: 50pt;
        padding-top: 40%;
        font-style: normal;
        font-weight: 100;
        font-family: "Soho Gothic" sans-serif;
    }
}
@media (min-width:1030px) and (max-width:1280px){
    .mens{
        height: 494px;
        display: flex;
        justify-content: center;  /* Horizontally centers the content */
        align-items: center;      /* Vertically centers the content */           /* Takes the full viewport height */
        text-align: center;
    }
    .card{
        border-radius: 0%;
        border: 0%;
        border-color: rgb(255, 255, 255);
        width: calc(100% - 50px);
        height: 650px;
    }
    #pdc{
        width: calc(100% - 670px);
        padding: 0 0 0 50px; 
    }
    .tpimg img{
        width: calc(100% - 20px);
        padding-top: 252.5px;
        margin: 0px 0px 0px 0px ;
    }
    .color-img{
        width: 135px;
    }
    .productlist{
        padding: 0 5px 0 5px;
        justify-content: center;
    }
    .collection-ro{
        gap: 20px;
    }
    .tpd{
        width: calc(100% - 20px);
        height: 450px;
    }
    .productlist{
        padding: 0 20px 0 20px;
        justify-content: center;
    }
    .card-body{
        padding: 0 0 0 0;
        align-content: center;
    }
    .card-title{
        font-style: normal;
        font-weight: lighter;
        font-family: "Soho Gothic" sans-serif;
        padding-top: 15px;
        font-size: 15pt;
        color: rgba(0, 0, 0, 0.491);
    }
    .title{
        font-size: 50pt;
        padding-top: 40%;
        font-style: normal;
        font-weight: 100;
        font-family: "Soho Gothic" sans-serif;
    }
}
  