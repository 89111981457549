@media (max-width: 450px){
    .icon{
        width: 50px;
    }
    .iic{
        top: calc(100% - 8%);
        left: calc(100% - 15%);
        position: fixed;
        
    }
}
@media (max-width: 1024px){
    .icon{
        width: 80px;
    }
    .iic{
        top: calc(100% - 8%);
        left: calc(100% - 10%);
        position: fixed;
        
    }
}

@media (min-width: 1025px) and (max-width: 2400px){
    .icon{
        width: 80px;
    }
    .iic{
        top: calc(100% - 10%);
        left: calc(100% - 8%);
        position: fixed;
        
    }
}