.row {
    /* justify-content: space-between; */
    display: flex;
    font-size: 13px;
}
.form-control {
    margin-top: 20px;
    width: 300px;
    font-size: 13px;
    border-radius: 0;
}
input {
    font-size: 12px;
    width: 100px;
    height: 40px;
    text-align: center;
}
.container .main{
  margin-top: 50px;
}
input:hover {
    border: 1px solid #000;

}
.btn {
    margin-top: 20px;
    position: relative;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 30px;
    letter-spacing: 1px;
    background: #000;
    border: 1px solid #000;
    text-decoration: none;
    z-index: 1;
    border-radius: 0;
    transition: 1s;
    /* overflow: hidden;  */
  }
  
  .btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: -1;
    transform-origin: left;
    transition: transform 0.5s ease;
    transform: scaleX(1);
  }
  
  .btn:hover::before {
    transform-origin: right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
  }
  
  .btn:hover {
    color: #000;
    border: 1px solid #000;
    background: #fff;
  }
  
  @media screen and(max-width:768px) {
    .main {
        display: inline-block;
    }
  }