.wrapper-img {
    max-width: calc(100% - 500px);
    position: relative;
}

.img-box {
    margin: auto;
    max-width: 90%;
    height: 734px;
    line-height: 734px;
}
.fth4{
    display: flex;
}
#pd-image {
    max-width: 100%;
}

.product-item-info {
    width: 500px;
}

.text {
    font-size: 13px;
}

.text-items {
    margin-top: 20px;
}

.product-item-info {
    margin-top: 50px;
}

.productlist-card {
    width: 130px;
    line-height: 130px;
    height: 130px;
    border-radius: 10px;
    overflow: hidden;
}

.productlist-card:hover {
    border: 1px solid #000;
}

.productlist-card img {
    transition: transform 0.3s ease-in-out;
    /* Smooth zoom effect */
}

.productlist-card:hover img {
    transform: scale(1.2);
    /* Zoom effect on hover, 1.2 means 20% bigger */
}

.textt {
    font-size: 14pt;
}

* {
    font-family: "Soho Gothic" sans-serif;
}
.product-item-pdc{
    display: flex;
}

.img-zoom-lens {
    position: absolute;
    border: 1px solid #d4d4d4;
    width: 100px;
    height: 100px;
    opacity: 0;
    /* background-color: white; */
    cursor: crosshair;
}

.img-zoom-result {
    opacity: 0%;
    margin-top: 600px;
    width: 500px;/* Size of the zoomed image */
    height: 200px;
    border: 1px solid #d4d4d4;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 320px; 
}
.img-box:hover .img-zoom-result {
    opacity: 100%;
}
.into {
    padding: 0 0 0 0;
    flex-wrap: wrap;
    
}
.incol{
    flex: none !important;
}
@media (max-width:445px){
    .img-zoom-lens {
        position: absolute;
        border: 1px solid #d4d4d4;
        width: 100px;
        height: 100px;
        opacity: 0;
        /* background-color: white; */
        cursor: crosshair;
        display: none;
    }
    .img-zoom-result {
        opacity: 0%;
        margin-top: 600px;
        width: 500px;/* Size of the zoomed image */
        height: 200px;
        border: 1px solid #d4d4d4;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 320px; 
        display: none;
    }
    .product-item-pdc{
        display: inline-block;
    }
    .wrapper-img {
        max-width: 400px;
        width: 400px;
        position: relative;
    }
    
    .img-box {
        margin: auto;
        max-width: 90%;
        height: 425px;
        line-height: 425px;
    }
    
    .product-item-info {
        width: 500px;
    }
    #pdcx{
        margin: 0 0 0 10px;
    }
    
    .text {
        font-size: 13px;
    }
    
    .text-items {
        margin-top: 20px;
        display: inline-block;
    }
    
    .product-item-info {
        margin-top: 50px;
        width: 100%;
    }
    
    .productlist-card {
        width: 100px;
        line-height: 100px;
        height: 100px;
        border-radius: 10px;
        overflow: hidden;
    }
    
    .productlist-card:hover {
        border: 1px solid #000;
    }
    
    .productlist-card img {
        transition: transform 0.3s ease-in-out;
        margin: 0 0 0 10px;
        /* Smooth zoom effect */
    }
    
    .productlist-card:hover img {
        transform: scale(1.2);
        /* Zoom effect on hover, 1.2 means 20% bigger */
    }
    
    .textt {
        font-size: 10pt;
    }
    .fth4{
        display: inline-block;
        font-size: 12pt;
    }
    .into{
        display: flex;
    }
    .into {
        padding: 0 0 0 0;
        flex-wrap: wrap;
        
    }
    .incol{
        flex: none !important;
    }
    .option-group{
        padding-left: 0;
        padding-right: 0;

    }
    

}