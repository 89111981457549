.container_logo {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.logo img {
  width: 200px;
}
.container_list {
  width: 100%;
  height: 57px;
}
.nav-link::after {
  content: "";
  position: absolute;
  background: #000;
  border-radius: 10px;
  height: 2px;
  width: 0%;
  left: 0;
  bottom: -10px;
  transition: 0.3s;
}
.nav-link:hover::after {
  width: 100%;
}

.menu_bar {
  border: none;
  outline: none;
  background: none;
  display: none;
}
 
 .dropdown-menu { 
    margin-top: -20px;
    border: none !important; 
    box-shadow: none !important; 
    border-radius: 0 !important;
    transition: border-top 0.3s ease-in-out;
    display: none;
    width: 200px; 
    background: #fff; 
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 999; 
  } 
  .dropdown-menu li a {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: normal;
    text-transform: capitalize;
  }

  
  .dropdown-menu li:hover { 
    display: block; 
    border: none;
  }

  .list_Items li {  
    padding: 8.4px 30px;
    transition: 0.7s ease-in-out;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  

@media screen  and (max-width: 768px){
  .container_logo {
    justify-content: space-between;
  }
  .nav-link::after{
    background: none;
  }
  .container_list {
    text-align: left;
    height: 0;
  }
  .menu_bar {
    display: block;
  }
  .list_Items li:hover { 
    border-bottom: none ;
  }
}